import {
  ACCESS_TOKEN_KEY,
  LAST_TENANT_KEY,
  LAST_TENANT_NAME_KEY,
  REFRESH_TOKEN_KEY,
  TENANT_KEY,
} from '../constants/auth.constants';
import JWTDecode from 'jwt-decode';
import { TokenData } from '../model/auth.model';

export const getAuthorizationHeader = () => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

  if (!accessToken) return;

  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

export const setAccessToken = (token: string) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
};

export const setTenantInStorage = (tenant: string) => {
  localStorage.setItem(TENANT_KEY, tenant);
};
export const deldeteTenantInStorage = () => {
  localStorage.removeItem(TENANT_KEY);
};

export const setLastTenantInStorage = (tenant: string) => {
  localStorage.setItem(LAST_TENANT_KEY, tenant);
};

export const setLastTenantNameInStorage = (tenant: string) => {
  localStorage.setItem(LAST_TENANT_NAME_KEY, tenant);
};

export const setRefreshToken = (token: string) => {
  localStorage.setItem(REFRESH_TOKEN_KEY, token);
};

export const getAccessToken = (): string => {
  try {
    return localStorage.getItem(ACCESS_TOKEN_KEY) as string;
  } catch {
    throw new Error('Cannot retrieve access token');
  }
};

export const getTenantFromStorage = (): string | null => {
  return localStorage.getItem(TENANT_KEY);
};

export const getLastTenantFromStorage = (): string | null => {
  return localStorage.getItem(LAST_TENANT_KEY);
};

export const getLastTenantNameFromStorage = (): string | null => {
  return localStorage.getItem(LAST_TENANT_NAME_KEY);
};

export const getRefreshToken = (): string => {
  try {
    return localStorage.getItem(REFRESH_TOKEN_KEY) as string;
  } catch {
    throw new Error('Cannot retrieve refresh token');
  }
};

export const decodeToken = (accessToken?: string): TokenData => {
  const token: string = accessToken || getAccessToken();

  try {
    return JWTDecode(token);
  } catch (e) {
    throw new Error('Cannot retrieve token');
  }
};

export const getRealmFromToken = (token: TokenData): string | undefined => {
  return token.iss?.split('/').slice(-1).pop();
};
