import { lazy } from 'react';

const Dashboard = lazy(() => import('../../Dashboard/Dashboard'));

const Chargers = lazy(() => import('../../Chargers/components/Chargers'));

const Sessions = lazy(() => import('../../Sessions/components/Sessions'));

const Statements = lazy(() => import('../../Statements/components/Statements'));

export { Dashboard, Chargers, Sessions, Statements };
