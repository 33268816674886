import evpLogo from '../../../../../assets/images/evp-logo.png';
import evpLogoBottom from '../../../../../assets/images/evp-logo-bottom.png';
import styled from '@emotion/styled';

type LogoProps = { noMargin?: boolean; stickToBottom?: boolean };

const StyledLogo = styled.img`
  margin: ${(props: LogoProps) => (props.noMargin ? '0' : '5em auto')};
  width: ${(props: LogoProps) => (props.noMargin ? '47px' : '100%')};
  bottom: ${(props: LogoProps) => (props.stickToBottom ? '0' : '30px')};
  height: ${(props: LogoProps) => (props.noMargin ? 'auto' : '60px')};
  object-fit: contain;
`;

export const Logo = (props: LogoProps) => (
  <StyledLogo
    {...props}
    className='evp-logo'
    src={props.stickToBottom ? evpLogoBottom : evpLogo}
    alt='evp-logo'
    loading='eager'
  />
);
