import React, { createContext, useContext, useState } from 'react';
import { predefinedDateRanges } from '../utils/DateRanges.utils';
import { Dayjs } from 'dayjs';
import { getPreviousYearsArray } from '../utils/DateRanges.utils';

export interface DateRangeState {
  dateRange: [Dayjs, Dayjs];
  statementsYear: number;
}

const yearsArray = getPreviousYearsArray();
const currentMonth = new Date().getMonth();
const defaultSelectedYear = currentMonth === 0 && yearsArray.length > 1 ? yearsArray[1] : yearsArray[0];

const initialState: DateRangeState = {
  dateRange: predefinedDateRanges[1].value,
  statementsYear: defaultSelectedYear,
} as DateRangeState;

const DateRangeContext = createContext<
  | {
      dateRange: [Dayjs, Dayjs];
      statementsYear: number;
      setDateRange: (dateRange: [Dayjs, Dayjs]) => void;
      setStatementsYear: (year: number) => void;
    }
  | undefined
>(undefined);

export const DateRangeProvider = ({ children }: { children: React.ReactNode }) => {
  const [dateRange, setDateRange] = useState(initialState.dateRange);
  const [statementsYear, setStatementsYear] = useState(initialState.statementsYear);

  return (
    <DateRangeContext.Provider value={{ dateRange, statementsYear, setDateRange, setStatementsYear }}>
      {children}
    </DateRangeContext.Provider>
  );
};

export const useDateRange = () => {
  const context = useContext(DateRangeContext);
  if (!context) {
    throw new Error('useDateRange must be used within a DateRangeProvider');
  }
  return { dateRange: context.dateRange, setDateRange: context.setDateRange };
};

export const useStatementsYear = () => {
  const context = useContext(DateRangeContext);
  if (!context) {
    throw new Error('useStatementsYear must be used within a DateRangeProvider');
  }
  return { statementsYear: context.statementsYear, setStatementsYear: context.setStatementsYear };
};
