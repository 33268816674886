const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_DAY = 86400;
//Types

export interface Revenue {
  amount: number;
  currency: string;
}

export const convertSecondsToDaysHoursMinutes = (totalSeconds: number): string => {
  if (!totalSeconds) return '0';
  if (totalSeconds < 60) return '< 1m';
  const days = Math.floor(totalSeconds / SECONDS_IN_DAY);
  const hours = Math.floor((totalSeconds % SECONDS_IN_DAY) / SECONDS_IN_HOUR);
  const minutes = Math.floor((totalSeconds % SECONDS_IN_HOUR) / 60);
  return `${days ? days + 'd' : ''} ${hours ? hours + 'h' : ''} ${minutes ? minutes + 'm' : ''}`;
};

export const convertHoursToDaysHoursMinutes = (totalSeconds: number): string => {
  const convertedTotalSeconds = totalSeconds * 3600;
  if (!convertedTotalSeconds) return '0';
  if (convertedTotalSeconds < 60) return '< 1m';
  const days = Math.floor(convertedTotalSeconds / SECONDS_IN_DAY);
  const hours = Math.floor((convertedTotalSeconds % SECONDS_IN_DAY) / SECONDS_IN_HOUR);
  const minutes = Math.floor((convertedTotalSeconds % SECONDS_IN_HOUR) / 60);
  return `${days ? days + 'd' : ''} ${hours ? hours + 'h' : ''} ${minutes ? minutes + 'm' : ''}`;
};

export const convertWattsToKilowatts = (watts: number) => watts / 1000;

export const formatAmountWithCurrency = (revenue: Revenue): string => {
  if (!revenue) return '';
  const currency = revenue.currency || '';
  const amount = revenue.amount?.toFixed(2);
  return `${amount} ${currency.toUpperCase()}`;
};
// there is probably a better way to do this
export const getDate = (dateStr: string): string => {
  const date = new Date(Date.parse(dateStr));
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC',
  };

  return date.toLocaleDateString('en-US', options);
};

export const getTime = (dateStr: string): string => {
  const date = new Date(Date.parse(dateStr));
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'UTC',
  };

  return date.toLocaleTimeString('en-US', options);
};

export const getUnixTimestamp = (dateStr: string): number => {
  const date = new Date(Date.parse(dateStr));
  return Math.floor(date.getTime() / 1000);
};

/** Transforms path to title
 * /user-profile -> User Profile
 * This helper is meant to be used in the Google Analytics 4 tag manager
 */
export const pathToTitle = (path: string): string => {
  return path
    .substring(1)
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const debounce = (func: () => void, timeout = 300) => {
  let timer: NodeJS.Timer;
  return (...args: []) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};
