import React, { createContext, useContext, useMemo, useState } from 'react';
import { Endpoints } from '../../App/API/model/API.endpoints';
import { ContractTypeEnum, ILocationContext } from './../model/Locations.model';
import { Location, LocationRate } from '../model/Locations.model';
import { UserContext } from './../../Users/context/Users.context';
import { KeycloakContext } from '../../App/Auth/providers/KeycloakProvider';
import useFetchMultiData from './../../App/API/hooks/useFetchMultiData';

export const LocationsProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentLocation, setCurrentLocation] = useState<Location | undefined>(undefined);
  const user = useContext(UserContext);
  const { isAuthenticatedInKeycloak } = useContext(KeycloakContext);

  const { data: multiLocations } = useFetchMultiData<LocationRate>({
    ids: user?.data.locations!,
    endpoint: Endpoints.LOCATION,
    options: {
      enabled: isAuthenticatedInKeycloak && user !== undefined,
      retry: 3,
    },
  });

  const locationsContainIaas = useMemo(() => {
    const items = multiLocations?.filter(item => item.content.location.contractType === ContractTypeEnum.iaas);

    return items && items.length > 0;
  }, [multiLocations]);

  const locations = multiLocations
    ?.map(response => response.content)
    .map(item => {
      // If there is no contractType in ddbb, default to capex
      if (!item.location.contractType) {
        item.location.contractType = ContractTypeEnum.capex;
      }

      return item;
    })
    .filter(node => node !== undefined);

  return (
    <LocationContext.Provider value={{ currentLocation, setCurrentLocation, locations, locationsContainIaas }}>
      {children}
    </LocationContext.Provider>
  );
};

export const LocationContext = createContext<ILocationContext | null>(null);

export const useLocations = () => {
  const context = useContext(LocationContext);
  if (!context) {
    throw new Error('useLocations must be used within a LocationsProvider');
  }
  return {
    currentLocation: context.currentLocation,
    locations: context.locations,
    setCurrentLocation: context.setCurrentLocation,
    locationsContainIaas: context.locationsContainIaas,
  };
};
