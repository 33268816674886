import { Permission } from '../../../Users/model/Users.model';
import { useContext } from 'react';
import { UserContext } from '../../../Users/context/Users.context';
export const useCheckPermission = (resource: string, scope: string): boolean => {
  const user = useContext(UserContext);
  if (user && user.data.permissions) {
    return user.data.permissions.some(
      (permission: Permission) => permission.resource === resource && permission.scopes.includes(scope)
    );
  }
  return false;
};
