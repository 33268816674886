import { Logo } from '../../../../App/Sidebar/components/Logo/Logo';
import { LoadingContainer, LoadingContainerBox } from '../style/Loading.styles';
import { LinearProgress } from '@mui/material';

export const Loading = () => {
  return (
    <LoadingContainer data-testid={'loading'}>
      <Logo noMargin />
      <LoadingContainerBox>
        <LinearProgress variant='indeterminate' />
      </LoadingContainerBox>
    </LoadingContainer>
  );
};
