//Hooks
import { useCheckPermission } from './../../Auth/hooks/useCheckPermission';
import { useLocation } from 'react-router-dom';
//Constants
import { Resources, Scopes } from '../../Auth/model/auth.model';
import { AppRoutes } from './../../Routes/App.routes';
//Types
import { ISidebarLink } from 'spark-component-library';
//Icons
import { LuLayoutDashboard as DashboardIcon, LuTimer as SessionsIcon } from 'react-icons/lu';
import { PiChargingStationBold as ChargersIcon } from 'react-icons/pi';
import { HiOutlineDocumentReport as StatementsIcon } from 'react-icons/hi';

const useSidebarLinks = () => {
  const { pathname } = useLocation();
  //const { isIaas, currentLocation } = useCurrentLocation();
  //const { locationsContainIaas } = useLocations();

  //const statementsDisabled = (currentLocation && isIaas) || (!currentLocation && locationsContainIaas);

  let sidebarLinks: ISidebarLink[] = [
    {
      name: 'Overview',
      id: AppRoutes.DASHBOARD,
      icon: <DashboardIcon size={'24px'} />,
      display: useCheckPermission(Resources.Metrics, Scopes.Read),
      selected: pathname.includes(AppRoutes.DASHBOARD),
    },
    {
      name: 'Chargers',
      id: AppRoutes.CHARGERS,
      icon: <ChargersIcon size={'24px'} />,
      display: useCheckPermission(Resources.Chargers, Scopes.Read),
      selected: pathname.includes(AppRoutes.CHARGERS),
      category: 'Charger Management',
    },
    {
      name: 'Sessions',
      id: AppRoutes.SESSIONS,
      icon: <SessionsIcon size={'24px'} />,
      display: useCheckPermission(Resources.Sessions, Scopes.Read),
      selected: pathname.includes(AppRoutes.SESSIONS),
      category: 'Reports',
    },
    {
      name: 'Statements',
      id: AppRoutes.STATEMENTS,
      icon: <StatementsIcon size={'24px'} />,
      display: useCheckPermission(Resources.Statements, Scopes.Read) /* && !statementsDisabled*/,
      selected: pathname.includes(AppRoutes.STATEMENTS),
      category: 'Reports',
    },
  ];

  return sidebarLinks;
};
export default useSidebarLinks;
