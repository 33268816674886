import { styled } from '@mui/material';
export const StyledContainer = styled('div')`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const StyledInnerContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: 100%;
  }
  height: 100%;
  justify-content: flex-start;
`;

export const DashboardContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: inline-block;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  /* text-align: center; */
  position: relative;
`;
