//Components
import CustomSelect from '../../../Shared/components/CustomSelect/components/CustomSelect';
import { MenuItem } from '@mui/material';
//Utils
import { getPreviousYearsArray } from './../utils/DateRanges.utils';
//Hooks
import { useTheme } from '@mui/material';
//Type
import { CustomizedEvent } from '../../../Shared/components/Input/model/Input.model';
import { useState } from 'react';
import { useStatementsYear } from '../context/DateRanges.context';
const YearPicker = () => {
  const { setStatementsYear, statementsYear } = useStatementsYear();
  const yearsArray = getPreviousYearsArray();
  const [selectedYear, setSelectedYear] = useState(statementsYear);

  const onChange = (e: CustomizedEvent) => {
    const year = e.target.value as number;
    setSelectedYear(year);
    setStatementsYear(year);
  };

  const theme = useTheme();
  return (
    <CustomSelect value={selectedYear} onChange={onChange} sx={{ backgroundColor: theme.palette.accentWhite.main }}>
      {yearsArray.map(year => (
        <MenuItem key={year} value={year}>
          {year}
        </MenuItem>
      ))}
    </CustomSelect>
  );
};
export default YearPicker;
