import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import axios, { AxiosError, AxiosRequestConfig, CancelTokenSource } from 'axios';
import { ResponseModel } from '../model';
import { mapParamsWithRangeAndLocation, setAuthorizationHeader, setAxiosInstance } from '../utils/HTTP.utils';
import { required } from '../../AppEntry/utils/App.utils';
import { handleAPIError } from '../../../Shared/components/Snackbar/hooks/useCustomSnackbar';
import { LocationContext } from '../../../Locations/context/Locations.context';
import { KeycloakContext } from '../../Auth/providers/KeycloakProvider';
export const useHTTP = <K>(
  requestUrl: string = '',
  requestParams: (AxiosRequestConfig | null) | undefined = null,
  fetchFromStart: boolean = false,
  includeContext: boolean = false,
  cancelBeforeAnother: boolean = false,
  dateRange?: [Date, Date],
  offsetCorrection: boolean = true
) => {
  const sourceRef = useRef<CancelTokenSource>();
  const { isAuthenticatedInKeycloak } = useContext(KeycloakContext);
  const location = useContext(LocationContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  const [data, setData] = useState<ResponseModel<K> | null>(null);

  const axiosInstance = useMemo(() => setAxiosInstance(isAuthenticatedInKeycloak), [isAuthenticatedInKeycloak]);

  const cancelCurrentRequest = () => sourceRef.current?.cancel('Request cancelled');

  const toggleLoading = () => setLoading(load => !load);

  const prepareConfig = useCallback(
    (url: string, params?: AxiosRequestConfig | null) => {
      setAuthorizationHeader(axiosInstance);

      if (cancelBeforeAnother) cancelCurrentRequest();

      sourceRef.current = axios.CancelToken.source();

      let preparedParams: AxiosRequestConfig = {
        ...params,
        params: { ...requestParams?.params, ...params?.params },
      };

      if (includeContext) {
        preparedParams = {
          ...preparedParams,
          ...mapParamsWithRangeAndLocation(preparedParams, dateRange, location?.currentLocation, offsetCorrection),
        };
      }

      const config: AxiosRequestConfig = {
        ...preparedParams,
        url,
        method: params?.method || 'get',
        cancelToken: !requestUrl.includes('token') ? sourceRef.current.token : undefined,
      };

      return config;
    },
    [
      requestUrl,
      cancelBeforeAnother,
      includeContext,
      location?.currentLocation,
      dateRange,
      requestParams,
      axiosInstance,
      offsetCorrection,
    ]
  );

  const fetch = useCallback(
    async <T>(url: string, params?: AxiosRequestConfig | null): Promise<ResponseModel<T> | undefined> => {
      const config = prepareConfig(url, params);

      toggleLoading();

      try {
        const response = await axiosInstance.request(config);
        setData({ ...response.data, url });
        toggleLoading();
        return response.data;
      } catch (e) {
        const errorData = e as AxiosError;
        setError(errorData);
        handleAPIError(errorData.response?.data as AxiosError);
        toggleLoading();
      }
    },
    [axiosInstance, prepareConfig]
  );

  useEffect(() => {
    if (!required(requestUrl) || !fetchFromStart) return;

    fetch<K>(requestUrl, requestParams);

    return () => {
      cancelCurrentRequest();
    };
  }, [fetchFromStart, fetch, requestUrl, requestParams, location?.currentLocation, dateRange, includeContext]);

  return {
    loading,
    error,
    data,
    fetch,
  };
};
