import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const currentDate = dayjs().utc();

interface IPredefinedDateRange {
  label: string;
  value: [Dayjs, Dayjs];
  placement: string;
  id: number;
}

export const predefinedDateRanges: IPredefinedDateRange[] = [
  {
    label: 'Last day',
    value: [currentDate.subtract(1, 'day'), currentDate.subtract(1, 'day')],
    placement: 'left',
    id: 1,
  },
  {
    label: 'Last week',
    value: [
      currentDate.subtract(1, 'week').startOf('week').add(1, 'day').startOf('day'),
      currentDate.subtract(1, 'week').endOf('week').add(1, 'day').startOf('day'),
    ],
    placement: 'left',
    id: 2,
  },
  {
    label: 'Last month',
    value: [currentDate.subtract(1, 'month').startOf('month'), currentDate.subtract(1, 'month').endOf('month')],
    placement: 'left',
    id: 3,
  },
  {
    label: 'This month',
    value: [currentDate.startOf('month'), currentDate.endOf('month').subtract(1, 'day')],
    placement: 'left',
    id: 4,
  },
  {
    label: 'This year',
    value: [currentDate.startOf('year'), currentDate.endOf('year')],
    placement: 'left',
    id: 5,
  },
];

export const getPreviousYearsArray = () => {
  const currentYear = new Date().getFullYear();
  const minimumYear = 2023;
  const previousYears = [...Array(currentYear - minimumYear + 1)].map((_, index) => currentYear - index);
  return previousYears;
};

export const getPredefinedDateRangesWithoutTime = (dates: [Date, Date], includeOffset = true): string[] => {
  const dateOffset = includeOffset ? new Date().getTimezoneOffset() * 60000 : 0;
  const dateStart = new Date((dates[0].getTime() || 0) - dateOffset).toISOString().substring(0, 10);
  const dateEnd = new Date((dates[1].getTime() || 0) - dateOffset).toISOString().substring(0, 10);

  return [dateStart, dateEnd];
};
