import axios, { AxiosResponse, ResponseType } from 'axios';
import { getAuthorizationHeader } from '../../Auth//utils/auth.utils';
import { ErrorStatus, ResponseModel } from './../model/API.model';
import { EndpointsType } from '../model/API.endpoints';

export const createQueryKey = (
  endpoint: EndpointsType | string,
  userId?: string,
  organizationId?: string,
  params?: any
): string[] => {
  const key: string[] = [endpoint];
  if (params) {
    key.push(params);
  }
  if (userId) {
    key.push(userId);
  }
  if (organizationId) {
    key.push(organizationId);
  }
  return key;
};

// Define a function to fetch data from the API with an authorization header
export const fetchData = async <T>(
  endpoint: EndpointsType | string,
  params: any,
  isAuthenticated: boolean,
  responseType?: ResponseType
): Promise<ResponseModel<T>> => {
  const header = getAuthorizationHeader();
  if (!isAuthenticated || !header) {
    handleUnauthorizedStatus();
  }
  const url = `${endpoint}`;
  const headers = {
    ...header,
  };

  const response: AxiosResponse<ResponseModel<T>> = await axios.get(url, {
    params,
    headers,
    responseType,
    method: 'get',
  });

  if (response.status === ErrorStatus.UNAUTHORIZED) {
    handleUnauthorizedStatus();
  }
  return response.data;
};

export const handleUnauthorizedStatus = () => {
  localStorage.clear();
  window.location.reload();
};
