//Providers
import { KeycloakProvider } from './domains/App/Auth/providers/KeycloakProvider';
import { UserProvider } from './domains/Users/context/Users.context';
import { LocationsProvider } from './domains/Locations/context/Locations.context';
import { SidebarProvider } from './domains/App/Sidebar/Sidebar.context';
import { lightTheme } from './domains/Shared/themes/muiTheme';
import { ThemeProvider } from '@mui/material';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import SnackbarProvider from './domains/Shared/components/Snackbar/Snackbar';
import { DateRangeProvider } from './domains/App/DateRanges/context/DateRanges.context';
import AnalyticsProvider from './domains/Shared/analytics';
//Mocks
import { MockKeycloakProvider } from './domains/App/Auth/tests/mocks/keycloak.mock';
//Model
import { Query } from '@tanstack/react-query';
import { Endpoints } from './domains/App/API/model/API.endpoints';
//Date Config
import dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(utc.default);

// Persister configuration
const now = dayjs();
const midnight = dayjs().utc().endOf('day');
const miliSecondsLeft = midnight.diff(now, 'millisecond');
const disabledEndpointCaches: string[] = [Endpoints.REPORT_STATEMENTS];

// Providers configuration
interface ProviderConfig {
  component: React.JSXElementConstructor<any>;
  props?: any;
}

const providers: ProviderConfig[] = [
  { component: KeycloakProvider },
  {
    component: PersistQueryClientProvider,
    props: {
      client: new QueryClient({
        defaultOptions: {
          queries: {
            cacheTime: miliSecondsLeft, // Hours left to midnight utc
          },
        },
      }),
      persistOptions: {
        persister: createSyncStoragePersister({
          storage: window.localStorage,
        }),
        dehydrateOptions: {
          shouldDehydrateQuery: (query: Query) => !disabledEndpointCaches.includes(query.queryKey[0] as string),
        },
      },
    },
  },
  { component: UserProvider },
  { component: AnalyticsProvider },
  {
    component: ThemeProvider,
    props: { theme: lightTheme },
  },
  {
    component: SnackbarProvider,
  },
  { component: SidebarProvider },
  { component: LocationsProvider },
  { component: DateRangeProvider },
];

export const AppProviders: React.FC<{ children?: React.ReactNode; useMockKeycloak?: boolean }> = ({
  children,
  useMockKeycloak = false,
}) => {
  // Choose the provider based on the useMockKeycloak flag

  const modifiedProviders = providers.map(provider => {
    if (provider.component === KeycloakProvider && useMockKeycloak) {
      return { ...provider, component: MockKeycloakProvider };
    }
    return provider;
  });

  const renderProviders = (currentChildren: React.ReactNode) => {
    return modifiedProviders.reduceRight((kids, provider) => {
      const ProviderComponent = provider.component;
      return <ProviderComponent {...provider.props}>{kids}</ProviderComponent>;
    }, currentChildren);
  };

  return <>{renderProviders(children)}</>;
};

export default AppProviders;
