import { useCallback, useRef } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import {
  RUM_APPLICATION_ID,
  RUM_CLIENT_TOKEN,
  RUM_ENABLED,
  RUM_SESSION_REPLAY_SAMPLE_RATE,
  RUM_SESSION_SAMPLE_RATE,
  RUM_ENV,
} from '../../../config';
import { pathToTitle } from '../utils/shared.utils';
import { UserState } from './../../Users/model/Users.model';

export const useRUM = () => {
  const isInitialized = useRef(false);

  const initialize = useCallback(() => {
    if (RUM_ENABLED === 'true' && !isInitialized.current) {
      try {
        datadogRum.init({
          applicationId: RUM_APPLICATION_ID,
          clientToken: RUM_CLIENT_TOKEN,
          site: 'datadoghq.com',
          sessionSampleRate: parseInt(RUM_SESSION_SAMPLE_RATE, 10),
          sessionReplaySampleRate: parseInt(RUM_SESSION_REPLAY_SAMPLE_RATE, 10),
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          defaultPrivacyLevel: 'mask-user-input',
          env: RUM_ENV,
        });
      } catch (error) {
        console.error('Error initializing Datadog RUM:', error);
      }
    }
  }, []);

  const setUser = useCallback((user: UserState) => {
    if (isInitialized.current && user) {
      datadogRum.setUser({
        id: user.data.id,
        name: user.data.name,
        email: user.data.email,
        organization: user.organization,
      });
    }
  }, []);

  const trackPageView = useCallback(() => {
    if (isInitialized.current) {
      datadogRum.startView({
        name: pathToTitle(window.location.pathname),
      });
    }
  }, []);


  return { initialize, setUser, trackPageView, isInitialized };
};
