import { useHTTP } from '../../HTTP/hooks/useHTTP';
import { Endpoints } from '../../API/model/API.endpoints';
import { REACT_APP_OAUTH_URL } from '../../../../config';
export const AuthApi = () => {
  const http = useHTTP();
  const tenant = async (email: string) =>
    await http
      .fetch<Array<{id:string,name:string} | undefined>>(Endpoints.TENANTS, {
        method: 'get',
        params: { email },
      })
      .catch(error => {
        console.log(error);
      });

  const impersonateData = async (email: string) =>
    await http
      .fetch<Array<{ realmId: string; realmName: string; userId: string }> >(Endpoints.IMPERSONATE_DATA, {
        method: 'get',
        params: { email },
      })
      .catch(error => {
        console.log(error);
      });

  const impersonate = async (realm: string, userId: string) =>
    await http
      .fetch<string>(`${REACT_APP_OAUTH_URL}/admin/realms/${realm}/users/${userId}/impersonation`, {
        method: 'post',
        withCredentials: true,
      })
      .then((res: any) => {
        // if (res?.redirect) {
        //   window.location.reload();
        // }
      })
      .catch(error => {
        console.log(error);
      });

  return {
    http,
    tenant,
    impersonate,
    impersonateData,
  };
};
