import { AuthApi } from '../../api/Auth.api';
import { useFormControl } from '../../../../Shared/components/FormControl/hooks/useFormControl';
import { useContext, useMemo, useState } from 'react';
import { KeycloakContext } from '../../providers/KeycloakProvider';
import { ImpersonateCredentials, SignInCredentials, Useimpersonate } from '../model/SignIn.model';
import { INITIAL_CREDENTIALS, signInValidator, impersonateValidator } from '../validators/SignIn.validators';

export const useImpersonate = (): Useimpersonate => {
  const {impersonate,impersonateData} = AuthApi();
  const { setTenant, loginWithTenant, setCurrentUserEmail } = useContext(KeycloakContext)!;
  const [tenantOptions, setTenantOptions] = useState<Array<{ realmId: string, realmName: string, userId: string }>>();
  const [isLoading, setIsLoading] = useState(false);

  const impersonateUser = async (credentials: SignInCredentials) => {
    setIsLoading(true);
    const res = await impersonateData(credentials.email);
    if (!res) {
      setIsLoading(false);
      return;
    }
    if (res.content.length > 1) {
      setTenantOptions(res.content);
      setIsLoading(false);
    } else {
      const { userId, realmId } = res.content[0];
      impersonate(realmId, userId).then(() => {
        setIsLoading(false);
        if (setTenant && loginWithTenant && setCurrentUserEmail) {
          setTenant(realmId);
          loginWithTenant(realmId);
          setCurrentUserEmail(credentials.email);
        }
      });
    }
  };

  const redirectToRealm = (credentials: ImpersonateCredentials) => {
    setIsLoading(true);
    const userId = tenantOptions?.find((option)=>option.realmId===credentials.organization)?.userId
    if (setCurrentUserEmail && loginWithTenant && setTenant && credentials.email && credentials.organization && userId) {
      impersonate(credentials.organization, userId).then(() => {
        setIsLoading(false);
        setTenant(credentials.organization!);
        loginWithTenant(credentials.organization!);
        setCurrentUserEmail(credentials.email);
      });
    }
  };

  const resetForm = () => {
    form.resetForm();
    setTenantOptions(undefined);
  };

  const useFormConfig = (tenantOptions?: Array<{realmId: string, realmName: string, userId: string}>) => {
    return useMemo(() => {
      if (tenantOptions) {
        return {
          validator: impersonateValidator,
          handler: redirectToRealm,
        };
      } else {
        return {
          validator: signInValidator,
          handler: impersonateUser,
        };
      }
    }, [tenantOptions]);
  };

  const { validator, handler } = useFormConfig(tenantOptions);
  const form = useFormControl(INITIAL_CREDENTIALS, validator, handler);

  return {
    form,
    loading: isLoading,
    tenantOptions,
    resetForm,
  };
};
