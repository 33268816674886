import { styled } from '@mui/material';

export const LoadingContainerBox = styled('div')`
  width: 30%;
  margin-top: 2rem;
`;

export const LoadingContainer = styled('div')`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.palette.grey[200]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
