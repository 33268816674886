import { REACT_APP_API_PREFIX as API_PREFIX, REACT_APP_API_NEW_PREFIX as API_NEW_PREFIX } from './../../../../config';

export class Endpoints {
  static DASHBOARD = `${API_PREFIX}/dashboard`;
  static DASHBOARD_DATE_RANGE = `${API_PREFIX}/dashboard/daterange`;

  static AUTH = {
    LOGIN: `${API_PREFIX}/auth/token`,
    PASSWORD_RESET: `${API_PREFIX}/auth/token/passwordreset`,
    REFRESH_TOKEN: `${API_PREFIX}/auth/refreshtoken`,
    INVITATION_TOKEN: `${API_PREFIX}/auth/token/invitation`,
  };

  static SESSIONS = `${API_PREFIX}/sessions`;
  static CDR = `${API_PREFIX}/cdr`;
  static STATEMENTS = `${API_PREFIX}/statements`;
  static CHARGERS = `${API_PREFIX}/chargestations`;
  static DRIVER_RATES = `${API_PREFIX}/rates`;
  static API_KEY = (id: string) => `${API_PREFIX}/users/${id}/api-key`;

  static PAYOUTS = {
    LIST: `${API_PREFIX}/payouts`,
    LIST_AGGREGATED: `${API_PREFIX}/payouts/aggregated`,
    PAYOUT_BY_ID: (id: string) => `${API_PREFIX}/payouts/${id}`,
    GENERATE_REPORT: (payoutId: string) => `${API_PREFIX}/payouts/${payoutId}/generate-report`,
  };

  static ROLES = {
    ALL_ROLES: `${API_PREFIX}/roles`,
    AVAILABLE_ROLES: `${API_PREFIX}/roles/available`,
    USER_ROLES: (id: string) => `${API_PREFIX}/users/${id}/roles`,
    USER_ROLE_BY_ID: (userId: string, id: string) => `${API_PREFIX}/users/${userId}/roles/${id}`,
  };

  static USERS = {
    LIST: `${API_PREFIX}/users`,
    USER_BY_ID: (id: string) => `${API_PREFIX}/users/${id}`,
    PASSWORD_RESET: `${API_PREFIX}/users/passwordreset`,
    TENANTS: `${API_PREFIX}/users/tenants`,
    PERMISSIONS: `${API_PREFIX}/users/permissions`,
  };

  static INVITATION = {
    GET_INVITATION_BY_ID: (id: string) => `${API_PREFIX}/invitations/${id}`,
    SEND_USER_INVITATION: `${API_PREFIX}/invitations/users`,
    SEND_CUSTOMER_INVITATION: `${API_PREFIX}/invitations/customers`,
    SEND_DEVELOPER_INVITATION: `${API_PREFIX}/invitations/developers`,
  };

  static LOCATIONS = {
    LIST: `${API_PREFIX}/locations`,
    AVAILABLE: `${API_PREFIX}/invitations/availableLocations`,
    LOCATION_BY_ID: (id: string) => `${API_PREFIX}/locations/${id}`,
    LOCATIONS_BY_ORGANIZATION_ID: (id: string) => `${API_PREFIX}/organizations/${id}/locations`,
    LOCATION_BY_ORGANIZATION_AND_LOCATION_ID: (orgId: string, locationId: string) =>
      `${API_PREFIX}/organizations/${orgId}/locations/${locationId}`,
  };

  static ORGANIZATIONS = {
    LIST: `${API_PREFIX}/organizations`,
    ORGANIZATION_BY_ID: (id: string) => `${API_PREFIX}/organizations/${id}`,
  };

  static STRIPE = {
    ONBOARDING: (organizationId: string) => `${API_PREFIX}/organizations/${organizationId}/stripe/onboarding`,
    UPDATE: (organizationId: string) => `${API_PREFIX}/organizations/${organizationId}/stripe/update`,
  };

  static METRICS = {
    SINGLE: `${API_NEW_PREFIX}/metrics`,
    LIST: `${API_NEW_PREFIX}/metrics/list`,
    PRECACHE_SESSIONS: `${API_NEW_PREFIX}/metrics/precacheSessions`,
  };

  static METRICS_UNCACHED = {
    SINGLE: `${API_NEW_PREFIX}/metrics/uncached`,
  };

  static REPORTS = {
    STATEMENT: `${API_NEW_PREFIX}/reports/statement`,
  };
}
