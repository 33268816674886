import { Suspense } from 'react';
import { Loading } from '../../../Shared/components/Loading/components/Loading';
import { DashboardContainer, StyledContainer, StyledInnerContainer } from '../../AppEntry/styles/App.styles';
import SideBar from '../../Sidebar/components/Sidebar';
import Unauthorized from './Unauthorized';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Chargers, Dashboard, Sessions, Statements } from '../../Routes/App.lazyroutes';
import { AppRoutes } from '../../Routes/App.routes';
import { Resources, Scopes } from './../model/auth.model';
import { useCheckPermission } from './../hooks/useCheckPermission';
import { useContext } from 'react';
import { UserContext } from '../../../Users/context/Users.context';
// Google Analytics
import { KeycloakContext } from '../providers/KeycloakProvider';
import { LocationContext } from '../../../Locations/context/Locations.context';

const RoutesContainer = () => {
  //const { isIaas, currentLocation } = useCurrentLocation();
  //const { locationsContainIaas } = useLocations();

  //const statementsRouteIsDisabled = (currentLocation && isIaas) || (!currentLocation && locationsContainIaas);

  //Default page
  return (
    <StyledContainer>
      <SideBar />
      <StyledInnerContainer>
        <DashboardContainer>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route
                path={AppRoutes.DASHBOARD}
                element={<ProtectedRoute resource={Resources.Metrics} scope={Scopes.Read} element={<Dashboard />} />}
              />
              <Route
                path={AppRoutes.CHARGERS}
                element={<ProtectedRoute resource={Resources.Chargers} scope={Scopes.Read} element={<Chargers />} />}
              />
              <Route
                path={`${AppRoutes.SESSIONS}/*`}
                element={<ProtectedRoute resource={Resources.Sessions} scope={Scopes.Read} element={<Sessions />} />}
              />
              {
                /*!statementsRouteIsDisabled && */ <Route
                  path={`${AppRoutes.STATEMENTS}/*`}
                  element={
                    <ProtectedRoute resource={Resources.Statements} scope={Scopes.Read} element={<Statements />} />
                  }
                />
              }

              <Route path={`/*`} element={<Navigate to={AppRoutes.DASHBOARD} replace={true} />} />
            </Routes>
          </Suspense>
        </DashboardContainer>
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export const ProtectedRoute: React.FC<{
  element: React.ReactElement;
  resource: string;
  scope: string;
}> = ({ element, resource, scope }) => {
  const hasAccess = useCheckPermission(resource, scope);
  return hasAccess ? element : <Unauthorized />;
};

const AuthenticatedRoutes = () => {
  const user = useContext(UserContext);
  const locations = useContext(LocationContext);
  const { isAuthenticatedInKeycloak } = useContext(KeycloakContext);

  return (
    <div data-testid='authenticatedRoutesContainer'>
      {user && user.data.permissions && user.data.organizationId && locations && isAuthenticatedInKeycloak ? (
        <RoutesContainer />
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default AuthenticatedRoutes;
