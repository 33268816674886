import { useEffect, useState } from 'react';

type ScreenSize = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
};

const debounce = <F extends (...args: any[]) => void>(func: F, delay: number) => {
  let timer: ReturnType<typeof setTimeout>;
  return (...args: Parameters<F>) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const useScreenDetector = (): ScreenSize => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleWindowSizeChange = debounce(() => {
    setWidth(window.innerWidth);
  }, 200); // Adjust debounce delay as needed

  useEffect(() => {
    const debouncedHandleResize = debounce(handleWindowSizeChange, 200);

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [handleWindowSizeChange]);

  const isMobile: boolean = width <= 600;
  const isTablet: boolean = width <= 900;
  const isDesktop: boolean = width > 900;

  return { isMobile, isTablet, isDesktop };
};
