import { useLocation } from 'react-router-dom';

export const required = (value: any) => {
  const trimmedValue = value.toString().trim();
  return !!trimmedValue && trimmedValue !== '';
};

export const charLimit = (value?: string, limit: number = 255): boolean => {
  if (!value) return false;
  return value.length <= limit;
};

export const uppercaseFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

export const isObjectEmpty = (object: any) => !Object.keys(object).length;

export const filterObjectByProperty = (object: any, prop: string) => {
  return Object.keys(object)
    .filter(key => key.includes(prop))
    .reduce((obj: any, key: string) => {
      obj[key] = object[key];
      return obj;
    }, {});
};

export const useQuery = () => {
  const location = useLocation();
  return new URLSearchParams(location.search);
};

export const formattedDate = () => {
  const dateToday = new Date();
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  let dateArr = dateToday.toLocaleDateString('en-US', options).replace(/,/g, '').split(' ');

  [dateArr[1], dateArr[2]] = [dateArr[2], dateArr[1]];
  dateArr[1] = `, ${dateArr[1]}`;

  return (
    <>
      <span className='dayOfWeek'>{`${dateArr[0]}`}</span>
      {dateArr.slice(1).join(' ')}
    </>
  );
};

export const matchesMobileMedia = (): boolean => {
  const query = 'only screen and (max-width: 760px)';
  return window.matchMedia && window.matchMedia(query).matches;
};
