import * as Yup from 'yup';
import { FormError } from '../model/FormControl.model';

export const MUST_BE_A_POSITIVE = 'Must be a positive number';
const CHAR_MAX_LIMIT = 255;
const CHAR_MAX_MESSAGE = 'Too many characters';
const EMAIL_INFO = 'Invalid email';

export const prepareErrorsForNestedSchema = (errors: FormError) => {
  let result = {};
  for (const key in errors) {
    const splitKey = key.split('.').slice(1).join('.');
    const splitMessage = errors[key].split('.').slice(1).join('.');
    result = {
      ...result,
      [splitKey]: splitMessage,
    };
  }
  return result;
};

export const handleNestedObject = (prop: string, value: any, obj: any) => {
  const [key, nestedKey] = prop.split('.');
  if (!nestedKey) {
    obj[key] = value;
  } else {
    handleNestedObject(nestedKey as any, value, obj[key]);
  }
};

export class Validators<T> extends Yup.ObjectSchema<T | any> {
  static array() {
    return Yup.array();
  }

  static charLimit(length?: number) {
    return Yup.string().max(length || CHAR_MAX_LIMIT, CHAR_MAX_MESSAGE);
  }

  static numberRequired() {
    return Yup.number().required();
  }

  static required(message?: string) {
    return Yup.string().required(message);
  }

  static email(message?: string) {
    return Yup.string().email(message || EMAIL_INFO);
  }

  static matches(ref: string, message?: string) {
    const defaultMessage = message || 'Characters do not match';
    return Yup.string()
      .required()
      .oneOf([Yup.ref(ref), null], defaultMessage);
  }

  static async validateForms<K>(
    schema: Yup.ObjectSchema<K | any>,
    values: K[]
  ) {
    try {
      return await Promise.all(values.map(value => schema.validate(value)));
    } catch (e) {
      return false;
    }
  }
}
