import { JwtPayload } from 'jwt-decode';

interface TokenAdditionalData {
  name: string;
  userId: string;
  locations?: string[];
  organizationId?: string;
  phoneNumber?: string;
  email?: string;
  preferred_username?: string;
  email_verified?: boolean;
}

export type TokenData = TokenAdditionalData & JwtPayload;

export type KeycloakContextType = {
  ready: boolean;
  isAuthenticatedInKeycloak: boolean;
  logout?: () => void;
  tenant?: string | null;
  setTenant?: (value: string | null) => void;
  setRedirectToLogin?: (boolean: boolean) => void;
  currentUserEmail?: string;
  setCurrentUserEmail?: (value: string) => void;
  loginWithTenant?: (tenant: string) => void;
};

export const MASTER_REALM = 'master';

export enum Resources {
  Metrics = 'evp-resource--metrics',
  Chargers = 'evp-resource--chargers',
  Impersonate = 'evp-resource--impersonate',
  Sessions = 'evp-resource--sessions',
  Locations = 'evp-resource--locations',
  Statements = 'evp-resource--statements',
}
export enum Scopes {
  Create = 'evp-scope--create',
  Read = 'evp-scope--read',
  Update = 'evp-scope--update',
  Delete = 'evp-scope--delete',
}
