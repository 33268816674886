import { REACT_APP_BASE_URL_NEW, REACT_APP_API_NEW_PREFIX } from './../../../../config';

export const Endpoints = {
  METRICS_SINGLE: `${REACT_APP_BASE_URL_NEW}/${REACT_APP_API_NEW_PREFIX}/metrics`,
  METRICS_LIST: `${REACT_APP_BASE_URL_NEW}/${REACT_APP_API_NEW_PREFIX}/metrics/list`,
  METRICS_SINGLE_UNCACHED: `${REACT_APP_BASE_URL_NEW}/${REACT_APP_API_NEW_PREFIX}/metrics/uncached`,
  PRECACHE_SESSIONS: `${REACT_APP_BASE_URL_NEW}/${REACT_APP_API_NEW_PREFIX}/metrics/precacheSessions`,
  SESSIONS: `${REACT_APP_BASE_URL_NEW}/${REACT_APP_API_NEW_PREFIX}/sessions`,
  SESSIONS_UNCACHED: `${REACT_APP_BASE_URL_NEW}/${REACT_APP_API_NEW_PREFIX}/sessions/uncached`,
  CDR: `${REACT_APP_BASE_URL_NEW}/${REACT_APP_API_NEW_PREFIX}/cdr`,
  STATEMENTS: `${REACT_APP_BASE_URL_NEW}/${REACT_APP_API_NEW_PREFIX}/statements`,
  CHARGERS: `${REACT_APP_BASE_URL_NEW}/${REACT_APP_API_NEW_PREFIX}/chargestations`,
  REPORT_STATEMENTS: `${REACT_APP_BASE_URL_NEW}/${REACT_APP_API_NEW_PREFIX}/reports/statement`,
  TENANTS: `${REACT_APP_BASE_URL_NEW}/${REACT_APP_API_NEW_PREFIX}/users/allTenants`,
  USERS: `${REACT_APP_BASE_URL_NEW}/${REACT_APP_API_NEW_PREFIX}/users`,
  PERMISSIONS: `${REACT_APP_BASE_URL_NEW}/${REACT_APP_API_NEW_PREFIX}/users/permissions`,
  ORGANIZATIONS: `${REACT_APP_BASE_URL_NEW}/${REACT_APP_API_NEW_PREFIX}/organizations`,
  LOCATION: `${REACT_APP_BASE_URL_NEW}/${REACT_APP_API_NEW_PREFIX}/locations`,
  IMPERSONATE_DATA: `${REACT_APP_BASE_URL_NEW}/${REACT_APP_API_NEW_PREFIX}/users/allImpersonateData`,
} as const;
export type EndpointsType = typeof Endpoints[keyof typeof Endpoints];
