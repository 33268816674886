import { AxiosRequestConfig } from 'axios';

export interface ResponseModel<T> {
  content: T;
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  pageable?: Pageable;
  size?: number;
  sort?: Sort;
  totalElements?: number;
  totalPages?: number;
  url?: string;
}

interface Pageable {
  sort: Sort;
  offset: 0;
  pageNumber: 0;
  pageSize: 10;
}

interface Sort {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export type FetchAPI<T> = (
  url: string,
  params?: AxiosRequestConfig | null
) => Promise<ResponseModel<T> | undefined>;

export enum ErrorStatus {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
}
