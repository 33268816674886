import {
  Location,
  LocationAddress,
  Rate,
} from '../model/Locations.model';
import { round } from 'mathjs';

export enum QueryParam {
  LOCATION_ID = 'locationId',
  DAYS = 'days',
  DATESTART = 'fromDateIncluded',
  DATEEND = 'toDateIncluded',
}

export const extractCurrenciesFromRates = (
  availableRates: Rate[]
): string[] => {
  const ratesCurrencies = availableRates.map(rate => rate.currency);
  return Array.from(new Set(ratesCurrencies));
};

export const formatPriceAmount = (price?: number | string) => {
  const amount = +(price || 0);
  return round(amount, 2).toFixed(2);
};

export const getLocationName = (location?: Location) => {
  if (location) return location.name || displayFullLocation(location.address);
  else return 'All Locations';
};

export const displayFullLocation = (address?: LocationAddress): string => {
  if (!address) return '';
  return `${address.streetAndNumber}, ${address.state}, ${address.city} ${address.postalCode}, ${address.country}`;
};

export const locationsOptions = (locations: Location[]) => {
  if (!locations) return [];

  return locations.map(({ id, address, name }: Location) => ({
    label: name || displayFullLocation(address),
    id,
  }));
};

export const filterRates = (rates: Rate[], type: string, currency: string) =>
  rates
    .filter(
      rate =>
        rate.priceComponent.type.includes(type) &&
        rate.currency.includes(currency) &&
        (rate.minPrice.includingVat > 0 || rate.priceComponent.price === 0)
    )
    .sort((a, b) => {
      const valA = ((a.priceComponent.price as number) +
        a.minPrice.includingVat) as number;
      const valB = ((b.priceComponent.price as number) +
        b.minPrice.includingVat) as number;
      return valA > valB ? 1 : -1;
    });
