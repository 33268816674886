import { useQuery } from '@tanstack/react-query';
import { fetchData } from '../utils/API.utils';
import { ResponseModel } from '../../HTTP/model';
import { AxiosError } from 'axios';
import { ErrorStatus, IUseFetchMultiData } from '../model/API.model';
import { useContext } from 'react';
import { KeycloakContext } from '../../Auth/providers/KeycloakProvider';

const useFetchMultiData = <T>({ ids, endpoint, options, responseType }: IUseFetchMultiData) => {
  // Context
  const { isAuthenticatedInKeycloak, logout } = useContext(KeycloakContext);

  // Create function to fetch data for a single ID
  const fetchDataForId = (id: string) => {
    const url = `${endpoint}/${id}`;
    return fetchData<T>(url, {}, isAuthenticatedInKeycloak, responseType);
  };

  // Query function to fetch data for all IDs
  const fetchAllData = async () => {
    const promises = ids?.map(id => fetchDataForId(id));
    return Promise.all(promises);
  };

  // React Query's useQuery hook
  const { data, error, isLoading, isPreviousData, isSuccess, isFetching } = useQuery<
    ResponseModel<T>[] | undefined,
    AxiosError
  >({
    queryKey: ['multiFetch', endpoint, ids], // Key should include ids to uniquely identify the query
    queryFn: fetchAllData,
    enabled: options?.enabled,
    refetchOnWindowFocus: false,
    keepPreviousData: options?.keepPreviousData,
    staleTime: options?.staleTime || 0,
    cacheTime: options?.cacheTime,
    refetchInterval: (options?.refetchInterval as number) || false,
    retry: options?.retry || false,
  });

  if ((error?.request?.status as unknown as ErrorStatus) === ErrorStatus.UNAUTHORIZED && logout) {
    logout();
  }

  return { data, error, isLoading, isPreviousData, isSuccess, isFetching };
};

export default useFetchMultiData;
