export const globalTheme = {
  colors: {
    turquoise: '#0abaac',
    sunYellow: '#fde121',
    charcoalGrey: '#2a2c36',
    blueyGrey: '#939aac',
    paleGrey: '#f5f6fa',
    white: '#ffffff',
    turquoiseHalf: 'rgba(10, 186, 172, 0.5)',
    brownishGrey: '#6e6e6e',
    error: '#8b2e2e',
  },
};
