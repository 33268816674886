import { ImpersonateCredentials, SignInCredentials } from '../model/SignIn.model';
import { Validators } from '../../../../Shared/components/FormControl/utils/FormControl.utils';

export const INITIAL_CREDENTIALS: SignInCredentials = {
  email: ''
};

export const INITIAL_IMPERSONATE_CREDENTIALS: ImpersonateCredentials = {
  email: ''
};

export const signInValidator = new Validators({
  email: Validators.email().required(),
});

export const impersonateValidator = new Validators({
  email: Validators.email().required(),
  organization: Validators.required(),
});

export const redirectToRealmValidator = new Validators({
  email: Validators.email().required(),
  organization: Validators.required(),
});
