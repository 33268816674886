import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';

export const useCustomSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();

  const success = (message: string) =>
    enqueueSnackbar(message, {
      variant: 'success',
    });

  const warning = (message: string) =>
    enqueueSnackbar(message, {
      variant: 'warning',
    });

  const info = (message: string) =>
    enqueueSnackbar(message, {
      variant: 'info',
    });

  const error = (message: string) =>
    enqueueSnackbar(message, {
      variant: 'error',
    });

  return {
    success,
    warning,
    info,
    error,
  };
};

export const useErrorSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();

  const error = (message: string) =>
    enqueueSnackbar(message, {
      variant: 'error',
    });

  window.alert = (message: string) => {
    return error(message);
  };
};

export const handleAPIError = (error: AxiosError) => {
  console.log(error);
  const message = error.message;
  if (!Array.isArray(message)) {
    alert(message);
  } else {
    message.forEach(value => alert(value));
  }
};

export const handleExportError = (errorMessage: string) => {
  alert(errorMessage);
};
