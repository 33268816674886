import { globalTheme } from '../../../themes';

export const SNACKBAR_CONFIG = {
  maxSnack: 3,
  classes: {
    variantSuccess: 'success',
    variantError: 'error',
    variantWarning: 'warning',
    variantInfo: 'info',
  },
  styles: {
    success: globalTheme.colors.turquoise,
    error: globalTheme.colors.error,
    warning: globalTheme.colors.sunYellow,
    info: globalTheme.colors.brownishGrey,
  },
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};
