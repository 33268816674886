import { useCallback } from 'react';
import ReactGA from 'react-ga4';
import { GA_ENABLED, GA_TRACKING_ID } from '../../../config';
import { pathToTitle } from '../utils/shared.utils';
import { UserState } from '../../Users/model/Users.model';

export const useGA = () => {
  const initialize = useCallback(() => {
    if (GA_ENABLED === 'true' && !ReactGA.isInitialized) {
      ReactGA.initialize(GA_TRACKING_ID);
    }
  },[]);

  const setUser = useCallback((user: UserState) => {
    if (ReactGA.isInitialized && user) {
      ReactGA.set({
        userId: user.data.id,
        userName: user.data.name,
        userEmail: user.data.email,
        userOrganization: user.organization,
      });
    }
  }, []);

  const trackPageView = useCallback(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: pathToTitle(window.location.pathname),
    });
  }, []);

  return { initialize, setUser, trackPageView };
};
