import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import Keycloak from 'keycloak-js';
import {
  getAccessToken,
  getRefreshToken,
  getTenantFromStorage,
  setAccessToken,
  setRefreshToken,
  setTenantInStorage,
} from '../utils/auth.utils';
import { REACT_APP_OAUTH_URL, REACT_APP_OAUTH_CLIENT } from '../../../../config';
import { KeycloakContextType, MASTER_REALM, Resources, Scopes } from '../model/auth.model';
import { clearInterval, setInterval } from 'worker-timers';
import { useCheckPermission } from '../hooks/useCheckPermission';
import { useLocation } from 'react-router-dom';
import { Dialog, DialogContent, DialogContentText } from '@mui/material';

const initializeKeycloak = (realm: string): { keycloakInstance: Keycloak; checkAuthentication: Promise<boolean> } => {
  const keycloakInstance = new Keycloak({
    realm,
    url: REACT_APP_OAUTH_URL as string,
    /**
     * If the realm is master, it indicates that a support user is attempting to authenticate.
     * In this case, authentication is performed against the security-admin-console to avoid
     * including roles from all realms in the token, which could lead to a 431 HTTP error.
     * The server, in this scenario, looks up roles in the user's info rather than in the token.
     * If the realm is not master, the user is authenticated against their respective realms.
     */
    clientId: realm === 'master' ? 'security-admin-console' : (REACT_APP_OAUTH_CLIENT as string),
  });

  const checkAuthentication = keycloakInstance.init({
    onLoad: 'check-sso',
    //checkLoginIframe: true,
    //silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
    token: getAccessToken(),
    refreshToken: getRefreshToken(),
    redirectUri: window.location.protocol + '//' + window.location.host,
  });

  console.log('Keycliak instance created');

  return { keycloakInstance, checkAuthentication };
};

const updateToken = (keycloakInstance: Keycloak) => {
  if (keycloakInstance.token) {
    keycloakInstance
      .updateToken(60) // Refresh token if it expires in the next 60 seconds
      .then(refreshed => {
        if (refreshed) {
          console.log('[update token] token refreshed');
          setAccessToken(keycloakInstance.token!);
          if (keycloakInstance.refreshToken) {
            setRefreshToken(keycloakInstance.refreshToken);
          }
        }
      })
      .catch(error => {
        console.error('Failed to refresh token:', error);
      });
  }
};

export const KeycloakProvider = ({ children }: { children: React.ReactNode }) => {
  const [keycloak, setKeycloak] = useState<Keycloak>();
  const [ready, setReady] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [tenant, setTenant] = useState<string | null>(null);
  const [currentUserEmail, setCurrentUserEmail] = useState<string>('');
  const isSupportUser = useCheckPermission(Resources.Impersonate, Scopes.Read);
  const location = useLocation();
  const updateTokenInterval = useRef<number>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const logout = useCallback(() => {
    // FIXME: the callback never gets called! Please fix!
    console.log('logout');
    localStorage.clear();
    keycloak?.logout();
  }, [keycloak]);

  /*useEffect(() => {
    if (redirectToLogin && tenant) {
      const { keycloakInstance } = initializeKeycloak(tenant);

      keycloakInstance.login({
        loginHint: currentUserEmail,
        redirectUri: window.location.protocol + '//' + window.location.host + '?tenant=' + tenant,
      });
    }
  }, [tenant, redirectToLogin, currentUserEmail]);*/

  useEffect(() => {
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
      window.onpageshow = function (event) {
        if (event.persisted) {
          window.location.reload();
        }
      };
    }
  }, []);

  const loginWithTenant = (tenant: string) => {
    const { keycloakInstance } = initializeKeycloak(tenant);

    keycloakInstance.login({
      loginHint: currentUserEmail,
      redirectUri: window.location.protocol + '//' + window.location.host + '?tenant=' + tenant,
    });
  };

  const destroyTimer = () => {
    if (updateTokenInterval.current) {
      console.log('[update token] clear interval');
      clearInterval(updateTokenInterval.current);
    }
  };

  useEffect(() => {
    //If tenant is set on local storage, keycloak will try to authenticate
    const locationParams = new URLSearchParams(location.search);
    const tenantInUrl = locationParams.get('tenant');
    const realm = tenantInUrl || getTenantFromStorage();

    console.log('tenant:', realm);

    if (realm) {
      const { keycloakInstance, checkAuthentication } = initializeKeycloak(realm);

      setKeycloak(keycloakInstance);

      //If user is already authenticated, acces tokens will be set
      checkAuthentication
        .then(isAuthenticated => {
          setIsAuthenticated(isAuthenticated);
          setReady(true);

          console.log('authenticated?', isAuthenticated);

          if (isAuthenticated) {
            if (tenantInUrl) {
              console.log('Tenant saved in storage:', tenantInUrl);
              setTenantInStorage(tenantInUrl);
            }
            // //Logs user out, if user is logged into master realm, but its not a support user
            if (tenant === MASTER_REALM && !isSupportUser) {
              logout();
              return;
            }

            if (keycloakInstance.token) {
              setAccessToken(keycloakInstance.token);
              if (keycloakInstance.refreshToken) {
                setRefreshToken(keycloakInstance.refreshToken);
              }
            }

            updateTokenInterval.current = setInterval(
              () => {
                console.log('[update token] update');
                updateToken(keycloakInstance);
              },
              //(keycloak?.tokenParsed?.exp! - keycloak?.tokenParsed?.iat! - 10) * 1000
              20000
            );
          } /* else {
            if (tenant) {
              keycloakInstance.login({
                loginHint: currentUserEmail,
                redirectUri: window.location.protocol + '//' + window.location.host + '?tenant=' + tenant,
              });
            }
          }*/
        })
        .catch(error => {
          setErrorMessage('Error trying to initialize authentication. Please try again later.');
          console.error('Failed to initialize Keycloak:', error);
        });
    } else {
      setReady(true);
    }

    return () => {
      console.log('[update token] remove interval from unmount');
      destroyTimer();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*useEffect(() => {
    // Set an interval to update the token periodically
    if (keycloak && isAuthenticated) {
      console.log('[update token] set interval');
      destroyTimer();

      updateTokenInterval.current = setInterval(
        () => {
          console.log('[update token] update');
          updateToken(keycloak);
        },
        //(keycloak?.tokenParsed?.exp! - keycloak?.tokenParsed?.iat! - 10) * 1000
        10000
      );
      // Clearing token gets called when the component is mounted.
      // We don't need to clear it since we only
      return () => {
        console.log('[update token] remove interval from unmount');
        destroyTimer();
      };
    }
  }, [keycloak, keycloak?.tokenParsed, keycloak?.authenticated, isAuthenticated]);*/

  return (
    <KeycloakContext.Provider
      value={{
        ready,
        isAuthenticatedInKeycloak: isAuthenticated,
        logout,
        tenant,
        setTenant,
        currentUserEmail,
        setCurrentUserEmail,
        loginWithTenant,
      }}
    >
      <Dialog open={errorMessage !== undefined}>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>{errorMessage}</DialogContentText>
        </DialogContent>
      </Dialog>
      {errorMessage === undefined && children}
    </KeycloakContext.Provider>
  );
};

export const KeycloakContext = createContext<KeycloakContextType>({ ready: false, isAuthenticatedInKeycloak: false });

export const useKeycloak = () => useContext(KeycloakContext);