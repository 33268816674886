import React, { createContext, useContext, useEffect, useState } from 'react';
import { useScreenDetector } from './../../Shared/hooks/useScreenDetector';
interface ISidebarProvider {
  isCollapsed: boolean;
  setIsCollapsed: (value: boolean) => void;
  isMobileCollapsed: boolean;
  setIsMobileCollapsed: (value: boolean) => void;
}

export const SidebarProvider = ({ children }: { children: React.ReactNode }) => {
  const { isMobile } = useScreenDetector();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [isMobileCollapsed, setIsMobileCollapsed] = useState<boolean>(true);

  useEffect(() => {
    if (isMobile && isCollapsed) {
      setIsCollapsed(false);
    }
  }, [isMobile, isCollapsed]);

  return (
    <SidebarContext.Provider value={{ isCollapsed, setIsCollapsed, isMobileCollapsed, setIsMobileCollapsed }}>
      {children}
    </SidebarContext.Provider>
  );
};

export const SidebarContext = createContext<ISidebarProvider | null>(null);

export const useSidebar = () => useContext(SidebarContext);
