//Components
import {TextField, Button, Select } from 'spark-component-library'
import { Footer } from './Footer';
import { SignInLink } from './SignInLink';
//Styles
import { SignInContainer, SignInForm, SignInImg, SignInTitle ,StyledForm, AnimatedInputContainer, NotYourOrganization, NotYourOrganizationLink} from './SignIn.styles';
//Assets
import Img from '../../../../../assets/images/signin-image.jpg';
//Hooks
import { useSignIn } from '../hooks/useSignIn'; 
import { capitalizeFirstLetter } from '../../../../Shared/components/HeaderContainer/utils';

const SignIn = () => {
  const {form,loading,tenantOptions,resetForm} = useSignIn()
  const emailError: string|undefined = form.hasError('email') ? capitalizeFirstLetter(form.hasError('email')!) : undefined;
  const tenantError: string|undefined = form.hasError('organization') ? capitalizeFirstLetter(form.hasError('organization')!) : undefined;

    return (
    <SignInContainer data-testid='signInPage'>
      <SignInForm>
        <SignInLink/>
        <StyledForm onSubmit={form.handleSubmit}>
          <SignInTitle>Sign in to EVPassport Cloud</SignInTitle>
          <TextField
            name='email'
            label={'Work Email'}
            inputProps={{
              "data-testid": "email",
            }}
            value={form.value.email}
            autoComplete='username'
            onChange={e => form.handleChange(e)}
            error={form.hasError('email') ? true : false}
            helperText={emailError}
            placeholder='Enter your work email...'
            fullWidth
            type={"email"}
            isPassword={false}
            disabled={tenantOptions?true:false}
          />
          {tenantOptions &&
            <>
              <AnimatedInputContainer>
                <Select 
                  name='organization'
                  error={form.hasError('organization') ? true : false}
                  helperText={tenantError}  
                  onChange={(e)=>form.handleChange(e)} 
                  value={form.value.organization} 
                  placeholder='Choose your organization' 
                  fullWidth label={"Organization"} 
                  options={tenantOptions?.map((tenant=>({label:tenant.name,key:tenant.id})))}/>
              </AnimatedInputContainer>
            </>
          }
          <Button testId='signIn' type='submit' loading={loading} fullWidth variation={"primary" as any} size={"large"}>Continue</Button>
          {tenantOptions &&
              <NotYourOrganization>Not your organization? <NotYourOrganizationLink onClick={()=>resetForm()}>Click Here</NotYourOrganizationLink></NotYourOrganization>
          }
        </StyledForm>
        <Footer/>
      </SignInForm>
      <SignInImg alt='card' loading='eager' src={Img} />
    </SignInContainer>
  );
};
export default SignIn;
