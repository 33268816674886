import { Navigate, Route, Routes } from 'react-router-dom';
import AuthenticatedRoutes from '../Auth/AuthenticatedRoutes/AuthenticatedRoutes';
import { useKeycloak } from '../Auth/providers/KeycloakProvider';
import SignInPage from './../../App/Auth/SignIn/components/SignIn';
import { Loading } from '../../Shared/components/Loading/components/Loading';
import Impersonate from './../Auth/SignIn/components/Impersonate';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../Users/context/Users.context';
import { Resources, Scopes } from '../Auth/model/auth.model';
import { AppRoutes } from '../Routes/App.routes';
import { Permission } from '../../Users/model/Users.model';
import Health from '../../Shared/components/Health';
import AppProviders from '../../../App.providers';
import { useGA } from '../../Shared/analytics/useGA';
import { useRUM} from '../../Shared/analytics/useRUM';
const hasImpersonationPermissions = (permission: Permission) =>
  permission.resource === Resources.Impersonate && permission.scopes.includes(Scopes.Read);

const AppEntry = () => {
  const { trackPageView:pageViewGA } = useGA()
  const { trackPageView:pageViewRum } = useRUM()
   //Page trackers
   pageViewGA();
   pageViewRum();

  const { isAuthenticatedInKeycloak, ready, tenant } = useKeycloak();
  const user = useContext(UserContext);
  const [isSupport, setIsSupport] = useState<boolean>();

  useEffect(() => {
    if (user?.data.permissions) {
      setIsSupport(!!user?.data.permissions?.find(hasImpersonationPermissions));
    }
  }, [user]);

  if (!ready || tenant || (isSupport === undefined && isAuthenticatedInKeycloak)) {
    return <Loading />;
  }

  return (
    <AppProviders>
      <Routes>
        <Route path={AppRoutes.HEALTH} element={<Health />} />

        {/* Redirect logic for authenticated users */}
        {isAuthenticatedInKeycloak && !isSupport && <Route path='/*' element={<AuthenticatedRoutes />} />}

        {/* Impersonate Route for support users only */}
        {isAuthenticatedInKeycloak && isSupport && <Route path={AppRoutes.IMPERSONATE} element={<Impersonate />} />}

        {/* Login Route */}
        {!isAuthenticatedInKeycloak && <Route path={AppRoutes.LOGIN} element={<SignInPage />} />}

        {/* Redirect unauthenticated users trying to access Impersonate */}
        {!isAuthenticatedInKeycloak && (
          <Route path={AppRoutes.IMPERSONATE} element={<Navigate to={AppRoutes.LOGIN} replace />} />
        )}

        {/* Redirect unhandled routes */}
        <Route
          path='/*'
          element={
            <Navigate to={isAuthenticatedInKeycloak && isSupport ? AppRoutes.IMPERSONATE : AppRoutes.LOGIN} replace />
          }
        />
      </Routes>
    </AppProviders>
  );
};

export default AppEntry;
