//Styles
import { SignInLinkContainer } from './SignIn.styles';
import { Stack } from '@mui/material';
import { ReactElement } from 'react';
import evpLogo from '../../../../../assets/images/evp-logo.png';


export const SignInLink = ({ actionElement }: {actionElement?: ReactElement}) => (
  <SignInLinkContainer>
    <Stack direction='row' justifyContent='space-between' alignItems='center' style={{ width: '100%' }}>
      <a href='https://evpassport.com/' target='_blank' rel='noreferrer'>
        <img alt="logo" src={evpLogo} width={65}/>
      </a>
      {actionElement}
    </Stack>
  </SignInLinkContainer>
);
