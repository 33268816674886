import { ReactNode, FC, useContext, useEffect } from 'react';
import { UserContext } from '../../Users/context/Users.context';
import { useGA } from './useGA';
import { useRUM } from './useRUM';

type AnalyticsProviderProps = {
  children: ReactNode;
};

const AnalyticsProvider: FC<AnalyticsProviderProps> = ({ children }) => {
  const user = useContext(UserContext);
  const { initialize: initializeRUM, setUser: setRUMUser } = useRUM();
  const { initialize: initializeGA, setUser: setGAUser } = useGA();

  useEffect(() => {
    initializeRUM();
    initializeGA();
  }, [initializeRUM, initializeGA]);

  useEffect(() => {
    if(user){
      setRUMUser(user);
      setGAUser(user);
    }
  }, [user, setRUMUser, setGAUser]);

  return <>{children}</>;
};

export default AnalyticsProvider;
