import { useQuery } from '@tanstack/react-query';
import { ErrorStatus, IUseFetchData } from '../model/API.model';
import { fetchData, createQueryKey } from '../utils/API.utils';
import { ResponseModel } from '../../HTTP/model';
import { AxiosError } from 'axios';
import { useContext } from 'react';
import { KeycloakContext } from '../../Auth/providers/KeycloakProvider';

const UseFetchData = <T>({ params, endpoint, options, responseType }: IUseFetchData<T>) => {
  //Context

  const { isAuthenticatedInKeycloak, logout } = useContext(KeycloakContext);

  //Create key to cache each request
  const queryKey = createQueryKey(endpoint, params);

  const { data, error, isLoading, isPreviousData, isSuccess, isFetching } = useQuery<
    ResponseModel<T> | undefined,
    AxiosError
  >({
    queryKey: queryKey, // Pass the query key as an array
    queryFn: () => fetchData<T>(endpoint, params, isAuthenticatedInKeycloak, responseType),
    enabled: options?.enabled,
    refetchOnWindowFocus: false,
    keepPreviousData: options?.keepPreviousData,
    staleTime: options?.staleTime || 0,
    cacheTime: options?.cacheTime,
    refetchInterval: (options?.refetchInterval as number) || false,
    retry: options?.retry || false,
  });

  if ((error?.request?.status as unknown as ErrorStatus) === ErrorStatus.UNAUTHORIZED && logout) {
    logout();
  }

  return { data, error, isLoading, isPreviousData, isSuccess, isFetching };
};
export default UseFetchData;
