import { styled, keyframes } from '@mui/material';
import { spacingTokens, typographyTokens } from 'spark-component-library';

export const SignInContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  maxHeight: '100vh',
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  position:"relative",
  flexDirection: 'row',
  '@media (max-width: 768px)': {
    flexDirection: 'column',
  },

}));

export const SignInForm = styled('div')(({ theme }) => ({
  width: '50%',
  height: '100%',
  minHeight: '100vh',
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position:"relative",
  '@media (max-width: 1024px)': {
    width: '100%', 
  },
}));

export const StyledForm = styled('form')(() => ({
 width:"60%",
 display:"flex",
 flexDirection:"column",
 gap:spacingTokens.Spacing20,
 marginTop:'235px',
 '@media (max-width: 1024px)': {
  width: '80%',
},
}));

export const SignInImg = styled('img')(() => ({
  width: '50%',
  height: 'auto',
  minHeight: '100vh',
  '@media (max-width: 1024px)': {
    display: 'none', // Hide image on small screens
  },
}));

export const SignInTitle = styled('h1')(({ theme }) => ({
  ...typographyTokens.Heading4,
  color: theme.palette.TextPrimary.main,
  width:"100%",
  marginBottom:spacingTokens.Spacing4,
}));
export const ImpersonateTitle = styled('h1')(({ theme }) => ({
  ...typographyTokens.Heading4,
  color: theme.palette.TextPrimary.main,
  width:"100%",

}));
export const ImpersonateSubtitle = styled('h2')(({ theme }) => ({
  color: theme.palette.TextSecondary.main,
  width:"100%",
  whiteSpace:" nowrap !important",
  ...typographyTokens.Body3Medium,
  marginBottom:spacingTokens.Spacing4,
  marginTop:`-${spacingTokens.Spacing12}`,
}));


//Footer
export const FooterContainer = styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 3em;
  margin: 0 auto;
  padding: 0 40px;
`;



export const FooterList = styled('ul')(({ theme }) => ({
  padding: "0 0",
  color: "gray",
  display: "flex",
  width: "100%",
  gap: `${spacingTokens.Spacing24}`,
  flexWrap:"wrap",
  justifyContent:"center",
  position:"relative",
}));

export const FooterElement = styled('li')(({ theme }) => ({
  padding: "0 0",
  position:"relative",
  display:"flex",
  alignItems:"center",
  '&:not(:last-child):after':{
    content:'""',
    height:"100%",
    width:"1px",
    backgroundColor: theme.palette.TextSecondary.main,
    position:"absolute",
    top:0,
    right:`-${spacingTokens.Spacing12}`,
  },
}));




export const FooterLink = styled('a')(({ theme }) => ({
  ...typographyTokens.OthersTooltip,
  color: theme.palette.TextSecondary.main,
  position:"relative",
  textDecoration: "none",
}));

export const SignInLinkContainer = styled('div')`
  position:absolute;
  top:${spacingTokens.Spacing32};
  left:${spacingTokens.Spacing32};
`

const InputAnimation = keyframes`
 0% { transform:translateY(-100%);opacity: 0; }
 100% { transform:translateY(0%); opacity: 1; }
`

export const AnimatedInputContainer = styled('div')`
  width:100%;
  overflow:hidden;
  .MuiBox-root{
    transition:all 0.3s;
    animation-name: ${InputAnimation};
    animation-duration: 0.3s;
    transform:translateY(0%);
    opacity: 1;
  }
`


export const NotYourOrganization = styled('p')(({ theme }) => ({
  ...typographyTokens.OthersBody,
  color: theme.palette.TextPrimary.main,
  width:"100%",
  textAlign:"left"
}));
export const NotYourOrganizationLink = styled('a')(({ theme }) => ({
  ...typographyTokens.OthersBody,
  color: theme.palette.TextPrimary.main,
  cursor:"pointer"
})); 

export const GoBackLink = styled('a')(({ theme }) => ({
  ...typographyTokens.OthersBody,
  color: theme.palette.TextPrimary.main,
  display:"flex",
  alignItems:"center",
  cursor:"pointer",
})); 

