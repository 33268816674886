import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import useFetchData from '../../App/API/hooks/useFetchData';
import { Endpoints } from '../../App/API/model/API.endpoints';
import { decodeToken, getRealmFromToken } from '../../App/Auth/utils/auth.utils';
import { User, UserState } from './../model/Users.model';
import { KeycloakContext } from './../../App/Auth/providers/KeycloakProvider';
import { getAccessToken } from '../../App/Auth/utils/auth.utils';
import { TokenData } from '../../App/Auth/model/auth.model';
export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticatedInKeycloak, ready } = useContext(KeycloakContext);
  const [tokenData, setTokenData] = useState<TokenData>();
  const [userId, setUserId] = useState<string>();
  const [organizationId, setOrganizationId] = useState<string>();

  useEffect(() => {
    if (getAccessToken() && isAuthenticatedInKeycloak && ready) {
      const tokenData = decodeToken();
      const extractedOrganizationId = tokenData.organizationId || getRealmFromToken(tokenData);
      if (!extractedOrganizationId) {
        throw new Error('No organizationId in token');
      }
      setTokenData(tokenData);
      setUserId(tokenData.sub);
      setOrganizationId(extractedOrganizationId);
    }
  }, [isAuthenticatedInKeycloak, ready]);

  const { data: organization } = useFetchData<any>({
    endpoint: `${Endpoints.ORGANIZATIONS}/${organizationId}`,
    options: { enabled: isAuthenticatedInKeycloak && organizationId !== undefined, retry: 3 },
  });

  const { data: permissions } = useFetchData<any>({
    endpoint: Endpoints.PERMISSIONS,
    params: { id: userId },
    options: {
      enabled: isAuthenticatedInKeycloak && userId !== undefined,
      staleTime: Infinity,
      keepPreviousData: true,
      retry: 3,
    },
  });

  const user: User = useMemo(() => {
    return {
      activated: tokenData?.email_verified,
      email: tokenData?.email,
      enabled: tokenData?.email_verified,
      id: userId,
      locations: organization?.content?.locations || [],
      name: tokenData?.name,
      organizationId,
      permissions: permissions?.content,
    };
  }, [
    tokenData?.email_verified,
    tokenData?.email,
    tokenData?.name,
    userId,
    organization?.content.locations,
    organizationId,
    permissions?.content,
  ]);

  return (
    <UserContext.Provider value={{ data: user, organization: organization?.content }}>{children}</UserContext.Provider>
  );
};

export const UserContext = createContext<UserState | undefined>(undefined);

export const useUser = () => useContext(UserContext);
