// MockKeycloakProvider.js
import React, { ReactElement, ReactNode, useState } from 'react';
import { KeycloakContext } from './../../providers/KeycloakProvider';
import { getAccessToken, getTenantFromStorage } from '../../utils/auth.utils';
export const MockKeycloakProvider = ({ children }: { children: ReactElement | ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(getAccessToken() && getTenantFromStorage() ? true : false);
  const [ready] = useState(getAccessToken() && getTenantFromStorage() ? true : false);
  const [tenant, setTenant] = useState<string | null>(
    getAccessToken() && getTenantFromStorage() ? getTenantFromStorage() : null
  );
  const mockLogout = () => {
    // Mock logout functionality
    setIsAuthenticated(false);
    localStorage.clear();
  };

  return (
    <KeycloakContext.Provider
      value={{
        ready,
        isAuthenticatedInKeycloak: isAuthenticated,
        logout: mockLogout,
        tenant,
        setTenant,
      }}
    >
      {children}
    </KeycloakContext.Provider>
  );
};
