export enum AppRoutes {
  LOGIN = 'login',
  IMPERSONATE = 'impersonate',
  DASHBOARD = 'dashboard',
  CHARGERS = 'chargers',
  PAYOUTS = 'payouts',
  PERMISSIONS = 'permissions',
  SESSIONS = 'sessions',
  DRIVER_RATES = 'driver-rates',
  USERS = 'users',
  CONFIRM_EMAIL = 'confirm-email',
  CUSTOMERS = 'customers',
  CONFIRM_PASSWORD = 'confirm-password',
  DOCUMENTATION = 'docs',
  STATEMENTS = 'statements',
  HEALTH = 'health',
}
