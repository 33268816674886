//Components
import {TextField, Button, Select } from 'spark-component-library'
import { Footer } from './Footer';
import { SignInLink } from './SignInLink';
//Styles
import {GoBackLink,  SignInContainer, SignInForm, SignInImg ,StyledForm, AnimatedInputContainer, ImpersonateSubtitle,ImpersonateTitle} from './SignIn.styles';
//Assets
import Img from '../../../../../assets/images/signin-image.jpg';
import { HiChevronLeft } from 'react-icons/hi';
//Hooks
import { useImpersonate } from '../hooks/useImpersonate'; 
import { capitalizeFirstLetter } from '../../../../Shared/components/HeaderContainer/utils';
import { useContext } from 'react';
import { KeycloakContext } from '../../providers/KeycloakProvider';

const Impersonate = () => {
  const {form,loading,tenantOptions} = useImpersonate()
  const {logout}  = useContext(KeycloakContext)
  const emailError: string|undefined = form.hasError('email') ? capitalizeFirstLetter(form.hasError('email')!) : undefined;
  const tenantError: string|undefined = form.hasError('organization') ? capitalizeFirstLetter(form.hasError('organization')!) : undefined;
  const handleLogout = () => {
    if (logout) {
      logout();
    }
  };
    return (
    <SignInContainer data-testid='signInPage'>
      <SignInForm>   
      <SignInLink/>     
        <StyledForm onSubmit={form.handleSubmit}>
          <GoBackLink onClick={()=>handleLogout()}>
              <HiChevronLeft size={16}/> Go back
          </GoBackLink>
          <ImpersonateTitle>Impersonate</ImpersonateTitle>
          <ImpersonateSubtitle>Enter the email of the user you want to impersonate.</ImpersonateSubtitle>
          <TextField
            name='email'
            label={'Email'}
            inputProps={{
              "data-testid": "email",
            }}
            value={form.value.email}
            autoComplete='username'
            onChange={e => form.handleChange(e)}
            error={form.hasError('email') ? true : false}
            helperText={emailError}
            placeholder='Enter the user email...'
            fullWidth
            type={"email"}
            isPassword={false}
            disabled={tenantOptions?true:false}
          />
          {tenantOptions &&
            <>
              <AnimatedInputContainer>
                <Select 
                  name='organization'
                  error={form.hasError('organization') ? true : false}
                  helperText={tenantError}  
                  onChange={(e)=>form.handleChange(e)} 
                  value={form.value.organization} 
                  placeholder='Choose your organization' 
                  fullWidth 
                  label={"Organization"} 
                  options={tenantOptions?.map((tenant=>({label:tenant.realmName,key:tenant.realmId})))}/>
              </AnimatedInputContainer>
            </> 
          }
          <Button type='submit' loading={loading} fullWidth variation={"primary" as any} size={"large"}>Continue</Button>
        </StyledForm>
        <Footer/>
      </SignInForm>
      <SignInImg alt='card' loading='eager' src={Img} />
    </SignInContainer>
  );
};
export default Impersonate;
