import { createTheme, responsiveFontSizes } from '@mui/material';
import { lightTheme as sparkTheme } from 'spark-component-library';
import { ThemeOptions } from '@mui/material/styles';
const colors = {
  yellow: '#FEE021',
  black: '#000000',
  black_accent: '#0C0C0C',
  grey: '#313131',
  grey_light: '#F0F0F0',
  white: '#FFFFFF',
  white_accent: '#FAFAFA',
};



// Components have their light/dark properties as props so it is easier to handle more complex cases
// There are 2 booleans that are opposed to each other so that comparisons can be done as isLight? or isDark?
type componentProps = (isLight: boolean, isDark: boolean) => ThemeOptions;
const components: componentProps = (isLight, isDark) => {
  return {
    components: {
      MuiButton: {
        defaultProps: {
          disableRipple: true,
          disableTouchRipple: true,
          disableElevation: true,
          variant: 'contained',
        },
        styleOverrides: {
          root: {
            borderRadius: '8px !important',
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: 24,
            padding: 8,
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: isLight ? colors.yellow : colors.black_accent,
            },
          },
        },
        defaultProps: {
          disableRipple: true,
          disableTouchRipple: true,
        },
      },
      MuiCard: {
        defaultProps: {
          variant: 'elevation',
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            outline: 'none',
            border: 'none',
            borderRadius: '8px',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
          },
        },
      },
    },
  };
};

let theme = createTheme(sparkTheme, {
  shape: {
    borderRadius: 6,
  },
  typography: {
    fontFamily: 'Silka',
    fontWeight: '400',
  },

  ...components(true, false),
});

//Custom colors

theme = createTheme(theme, {
  // Custom colors created with augmentColor go here
  palette: {
    mode: 'light',
    primary: theme.palette.augmentColor({
      color: {
        main: colors.yellow,
      },
      name: 'primary',
    }),
    secondary: theme.palette.augmentColor({
      color: {
        main: colors.black,
      },
      name: 'secondary',
    }),
    white: theme.palette.augmentColor({
      color: {
        main: colors.white,
      },
      name: 'white',
    }),
    accentWhite: theme.palette.augmentColor({
      color: {
        main: colors.white_accent,
      },
      name: 'accentWhite',
    }),
    lightGrey: theme.palette.augmentColor({
      color: {
        main: colors.grey_light,
      },
      name: 'lightGrey',
    }),
    background: {
      default: colors.white,
      paper: colors.white_accent,
    },
  },
});

//Module augmentation

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    white: Palette['primary'];
    accentWhite: Palette['primary'];
    lightGrey: Palette['primary'];
  }
  interface PaletteOptions {
    white: PaletteOptions['primary'];
    accentWhite: PaletteOptions['primary'];
    lightGrey: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    white: true;
    accentWhite: true;
    lightGrey: true;
  }
}

// Make the themes responsive using responsiveFontSizes
export const lightTheme = responsiveFontSizes(theme);
export const darkTheme = responsiveFontSizes(theme);
