import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { getAuthorizationHeader, getRefreshToken } from '../../Auth/utils/auth.utils';
import { Endpoints } from '../constants/endpoints';
import { ErrorStatus } from '../model';
import { ACCESS_TOKEN_KEY } from '../../Auth/constants/auth.constants';
import { Location } from '../../../Locations/model/Locations.model';
import { QueryParam } from '../../../Locations/utils/LocationsContext.utils';
import {
  REACT_APP_API_PREFIX,
  REACT_APP_API_NEW_PREFIX,
  REACT_APP_BASE_URL,
  REACT_APP_BASE_URL_NEW,
} from './../../../../config';

const DEFAULT_LIST_SIZE = 10;

export const DEFAULT_LIST_PARAMS: AxiosRequestConfig = {
  params: {
    pageSize: DEFAULT_LIST_SIZE,
    page: 0,
  },
};

export const areDatesEqual = (dateRange: [Date, Date]) => {
  return (
    dateRange[0].getFullYear() === dateRange[1].getFullYear() &&
    dateRange[0].getMonth() === dateRange[1].getMonth() &&
    dateRange[0].getDate() === dateRange[1].getDate()
  );
};

export const mapParamsWithRangeAndLocation = (
  params: AxiosRequestConfig | null,
  dateRange?: [Date, Date],
  location?: Location,
  offsetCorrection: boolean = true
): AxiosRequestConfig => {
  const newDateRange: [Date, Date] | undefined = dateRange ? [...dateRange] : undefined;

  const dateOffset = offsetCorrection ? new Date().getTimezoneOffset() * 60000 : 0;
  const dateStart = new Date((newDateRange?.[0].getTime() || 0) - dateOffset).toISOString().substring(0, 10);
  const dateEnd = new Date((newDateRange?.[1].getTime() || 0) - dateOffset).toISOString().substring(0, 10);

  return {
    ...params,
    params: {
      ...params?.params,
      [QueryParam.DATESTART]: dateStart,
      [QueryParam.DATEEND]: dateEnd,
      [QueryParam.LOCATION_ID]: location?.id,
    },
  };
};

export const apiPathsToBeReplacedByNewEndpointPaths = [
  Endpoints.METRICS.SINGLE,
  Endpoints.METRICS_UNCACHED.SINGLE,
  Endpoints.METRICS.LIST,
  Endpoints.METRICS.PRECACHE_SESSIONS,
  Endpoints.SESSIONS,
  Endpoints.CHARGERS,
  Endpoints.ROLES.ALL_ROLES,
  Endpoints.STATEMENTS,
  Endpoints.CDR,
  Endpoints.ORGANIZATIONS.ORGANIZATION_BY_ID('(.+)'),
  Endpoints.LOCATIONS.LOCATION_BY_ID('(.+)'),
  Endpoints.REPORTS.STATEMENT,
  Endpoints.USERS.USER_BY_ID('(.+)'),
];

export const replaceOldEndpoints = (config: AxiosRequestConfig) => {
  if (apiPathsToBeReplacedByNewEndpointPaths.some(reg => config.url!.match(new RegExp(`^${reg}$`)))) {
    config.baseURL = config.baseURL?.replace(REACT_APP_BASE_URL as string, REACT_APP_BASE_URL_NEW as string);
    config.url = config.url?.replace(REACT_APP_API_PREFIX as string, REACT_APP_API_NEW_PREFIX);
  }
};

export const setAxiosInterceptor = (axiosInstance: AxiosInstance, isAuthenticated: boolean) => {
  axiosInstance.interceptors.request.use(config => {
    //console.info("Request", config.url);

    replaceOldEndpoints(config);

    return config;
  });
  axiosInstance.interceptors.response.use(
    response => response,
    (error: AxiosError) => {
      const status = error.response ? error.response.status : null;

      if (!isAuthenticated) {
        return Promise.reject(error);
      }

      switch (status) {
        case ErrorStatus.UNAUTHORIZED:
          return handleUnauthorizedStatus(error, axiosInstance);
        case ErrorStatus.FORBIDDEN:
          alert('You do not have a permission to perform this action!');
          break;
        default:
          return Promise.reject(error);
      }
    }
  );
};

export const setAuthorizationHeader = (axiosInstance: AxiosInstance) => {
  const header = getAuthorizationHeader();
  if (!header) return;

  axiosInstance.defaults.headers.common['Authorization'] = header.Authorization;
};

export const setAxiosInstance = (isAuthenticated: boolean) => {
  const newInstance = axios.create({
    baseURL: REACT_APP_BASE_URL,
  });

  setAxiosInterceptor(newInstance, isAuthenticated);

  return newInstance;
};

const handleUnauthorizedStatus = (error: AxiosError, axiosInstance: AxiosInstance) => {
  if (error.config?.url === Endpoints.AUTH.REFRESH_TOKEN) {
    return;
  }

  //if (getRefreshToken()) {
  //  return updateAccessToken(axiosInstance, error);
  //}

  alert('Session has expired');

  localStorage.clear();
  window.location.reload();

  return Promise.reject(error);
};

export const updateAccessToken = async (axiosInstance: AxiosInstance, error: AxiosError) => {
  const newInstance = axios.create({
    baseURL: REACT_APP_BASE_URL,
  });
  try {
    const { data } = await newInstance.request({
      method: 'post',
      url: Endpoints.AUTH.REFRESH_TOKEN,
      data: {
        refreshToken: getRefreshToken(),
      },
    });
    const { accessToken } = data.content;
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    return axiosInstance.request({
      ...error.config,
      headers: axiosInstance.defaults.headers.common,
    });
  } catch (error) {
    alert('Session has expired');
    localStorage.clear();
    window.location.reload();
    return Promise.reject(error);
  }
};
