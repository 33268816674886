import { useState } from 'react';
import { ObjectSchema } from 'yup';
import { ObjectShape } from 'yup/lib/object';
import {
  CustomizedEvent,
  CustomizedSubmit,
} from '../../Input/model/Input.model';
import {
  FormControlProps,
  FormError,
  ValidateError,
} from '../model/FormControl.model';
import {
  handleNestedObject,
  prepareErrorsForNestedSchema,
} from '../utils/FormControl.utils';
import {
  filterObjectByProperty,
  isObjectEmpty,
} from '../../../../App/AppEntry/utils/App.utils';

export const NO_MESSAGE = '[EXCEPTION] NO_MESSAGE';

export const useFormControl = <Values>(
  initialValues: Values,
  validation: ObjectSchema<ObjectShape>,
  onSubmit?: (values: Values) => void
): FormControlProps<Values> => {
  const [enabledValidationOnChange, setEnabledValidationOnChange] =
    useState(false);

  const [value, setValue] = useState<Values>(initialValues);
  const [errors, setErrors] = useState<FormError>();

  const handleErrors = (error?: ValidateError) => {
    if (!error || isObjectEmpty(error)) {
      setErrors({});
      return;
    }

    let errorsSet = {};
    for (const field of error?.inner) {
      errorsSet = { ...errorsSet, [field.path]: field.message };
    }

    setErrors(errorsSet);
  };

  const handleChange = async (event: CustomizedEvent) => {
    const formValue = { ...value };
    handleNestedObject(event.target.name, event.target.value, formValue);

    setValue(formValue);

    if (enabledValidationOnChange) await handleValidation(formValue);
  };

  const handleValidation = async (
    valueInput?: Values
  ): Promise<boolean | undefined> => {
    const formValue = valueInput || value;

    try {
      const validate = await validation.validate(formValue, {
        abortEarly: false,
      });

      if (validate && errors && !isObjectEmpty(errors)) {
        handleErrors();
      }

      return !!validate;
    } catch (e) {
      const error = e as ValidateError;
      handleErrors(error);
      setEnabledValidationOnChange(true);
      return false;
    }
  };

  const handleSubmit = async (event: CustomizedSubmit) => {
    event.preventDefault();

    if (!onSubmit) return;

    const isValid = await handleValidation();

    if (!isValid) return;

    onSubmit(value);
  };

  const resetForm = () => {
    setValue(initialValues);
    setErrors({});
    setEnabledValidationOnChange(false);
  };

  const hasError = (
    name: string,
    noMessage: boolean = false
  ): string | undefined => {
    if (errors?.[name] && noMessage) return NO_MESSAGE;
    return errors?.[name] || undefined;
  };

  const hasErrorsForIndex = (
    name: string,
    index: number
  ): FormError | undefined => {
    if (!errors) return;

    const property = `${name}[${index}]`;
    const filteredObj: FormError = filterObjectByProperty(errors, property);

    return prepareErrorsForNestedSchema(filteredObj);
  };

  return {
    value,
    hasError,
    hasErrorsForIndex,
    handleChange,
    handleSubmit,
    handleValidation,
    resetForm,
  };
};
