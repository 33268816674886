//Components

import YearPicker from '../../../../App/DateRanges/components/YearPicker';

//Types
import { AppRoutes } from '../../../../App/Routes/App.routes';

export const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

export const getSectionTitle = (pathname: string): string => {
  const splitRoute = pathname.split('/')[1];

  switch (true) {
    case pathname.includes(AppRoutes.CONFIRM_EMAIL):
      return 'New User';
    case pathname.includes('customers/') && !pathname.includes('add'):
      return 'Edit Customer';
    case splitRoute && !splitRoute.includes('-'):
      return capitalizeFirstLetter(splitRoute);
    case splitRoute?.includes('-'):
      return splitRoute.split('-').map(capitalizeFirstLetter).join(' ');
    default:
      return 'Dashboard';
  }
};

export const renderActions = (pathName: string) => {
  const slicedPath = pathName.substring(1);

  switch (slicedPath) {
    case AppRoutes.DASHBOARD:
      return <></>;
    case AppRoutes.STATEMENTS:
      return <YearPicker />;
    default:
      return <></>;
  }
};
