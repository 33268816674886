import { StyledSnackbar } from './styles/Snackbar.styles';
import { SNACKBAR_CONFIG } from './utils/Snackbar.utils';
import { SnackbarOrigin } from 'notistack';
import { useErrorSnackbar } from './hooks/useCustomSnackbar';

const AlertOverride = () => {
  useErrorSnackbar();
  return <></>;
};

const Snackbar = (props: any) => {
  return (
    <StyledSnackbar
      data-testid={'error'}
      maxSnack={SNACKBAR_CONFIG.maxSnack}
      classes={SNACKBAR_CONFIG.classes}
      anchorOrigin={SNACKBAR_CONFIG.anchorOrigin as SnackbarOrigin}
    >
      <AlertOverride />
      {props.children}
    </StyledSnackbar>
  );
};

export default Snackbar;
