import { ILocationSwitcher, ILocation } from 'spark-component-library';
import { LocationContext } from '../../../Locations/context/Locations.context';
import { useContext } from 'react';
import { SidebarContext } from '../Sidebar.context';

const useLocationSwitcherProps = () => {
  const locationData = useContext(LocationContext);
  const sidebarProvider = useContext(SidebarContext);
  const locations: ILocation[] | undefined = locationData?.locations?.map(
    node =>
      ({
        name: node.location.name,
        id: node.location.id,
        state: node.location.address.state,
      } as ILocation)
  );
  locations?.unshift({
    name: 'All Locations',
    id: 'alllocations',
    state: 'All',
  } as ILocation);

  const handleSetLocation = (id: string) => {
    const newCurrentLocation = locationData?.locations?.find(node => node.location.id === id)?.location;
    if (newCurrentLocation) {
      locationData?.setCurrentLocation(newCurrentLocation);
    } else {
      locationData?.setCurrentLocation(undefined);
    }
    sidebarProvider?.setIsMobileCollapsed(true);
  };
  const locationSwitcherProps: ILocationSwitcher = {
    locations: locations,
    setValue: id => handleSetLocation(id),
    value: locationData?.currentLocation?.id || 'alllocations',
  };
  return locationSwitcherProps;
};
export default useLocationSwitcherProps;
