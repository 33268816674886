export interface LocationMapProps {
  zoom?: number;
  isLoading?: boolean;
  currentLocation?: string;
  locations?: LocationRate[];
}

export interface ILocationContext {
  setCurrentLocation: (value: Location | undefined) => void;
  currentLocation?: Location | undefined;
  locations?: LocationRate[];
  locationsContainIaas?: boolean;
}

export interface LocationRate {
  location: Location;
  rate: Rate;
}

export interface Rate {
  id: string;
  countryCode: string;
  currency: string;
  minPrice: { includingVat: number };
  altText: AltText;
  priceComponent: PriceComponent;
}

export interface AltText {
  language: string;
  text: string;
}

export enum PriceComponentType {
  FLAT = 'FLAT',
  ENERGY = 'ENERGY',
  TIME = 'TIME',
}

export const PriceComponentTypeValue: { [key: string]: string } = {
  [PriceComponentType.ENERGY]: 'kWh',
  [PriceComponentType.TIME]: 'Hour',
};

export interface PriceComponent {
  price?: number | string;
  stepSize: number;
  type: PriceComponentType | string;
  vat: number | null;
}

export interface Location {
  id: string;
  name: string;
  active: boolean;
  operatorName: string;
  address: LocationAddress;
  coordinates: Coordinates;
  chargestations: string[];
  notes: string;
  images: any[];
  createdAt: string;
  updatedAt: string;
  openingHours: any[];
  deleted: boolean;
  timezone: string;
  organization: string;
  owner: string;
  publish: boolean;
  contractType?: ContractTypeEnum;
}

export enum ContractTypeEnum {
  capex = 'capex',
  iaas = 'iaas',
}

/*export interface Location {
  id: string;
  countryCode: string;
  partyId: string;
  name: string;
  address: LocationAddress;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  timeZone: string;
  chargestations: string[];
  coordinates: Coordinates;
  organizationIds: string[];
}*/

export interface Coordinates {
  latitude: number;
  longitude: number;
}

export interface LocationAddress {
  streetAndNumber: string;
  postalCode: string;
  city: string;
  country: string;
  state: string;
}
