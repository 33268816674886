import { InputLabel, Select, SelectProps, FormControl, FormHelperText } from '@mui/material';
import { CustomizedEvent } from '../../Input/model/Input.model';
import { capitalizeFirstLetter } from '../../HeaderContainer/utils';

interface CustomSelectProps extends Omit<SelectProps, 'onChange'> {
  onChange: (e: CustomizedEvent) => void;
  hideArrow?: boolean;
  testid?: string;
  hasError?: string;
  hideSelect?: boolean;
  label?: string;
  fullWidth?: boolean;
  helpertext?: string;
}

const CustomSelect = (props: CustomSelectProps) => {
  const { hasError, hideArrow, testid, hideSelect, label, ...restOfTheProps } = props;

  return (
    <FormControl fullWidth={props.fullWidth} sx={{ textAlign: 'left' }} color={props.color} variant={props.variant}>
      <InputLabel className='labelContainer'>{label}</InputLabel>
      <Select
        {...restOfTheProps}
        fullWidth
        value={props.value}
        placeholder={props.label}
        color={props.color}
        inputProps={{
          'data-testid': testid || props.name,
        }}
        variant='outlined'
        className={hideArrow ? 'hidden-arrow' : ''}
        // classes={useSelectClasses()}
        onChange={props.onChange}
      />
      {props.helpertext && (
        <FormHelperText error data-testid='error-message'>
          {capitalizeFirstLetter(props.helpertext)}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default CustomSelect;
