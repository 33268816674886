import styled from '@emotion/styled';
import { SnackbarProvider } from 'notistack';
import { SNACKBAR_CONFIG } from '../utils/Snackbar.utils';

export const StyledSnackbar = styled(SnackbarProvider)`
  &.success {
    background-color: ${SNACKBAR_CONFIG.styles.success};
    & > div,
    & > div > svg {
      color: white;
      fill: white;
    }
  }
  &.error {
    background-color: ${SNACKBAR_CONFIG.styles.error};
    & > *,
    & > div > svg {
      color: white;
      fill: white;
    }
  }
  &.warning {
    background-color: ${SNACKBAR_CONFIG.styles.warning};
    & > *,
    & > div > svg {
      color: #000;
      fill: #000;
    }
  }
  &.info {
    background-color: ${SNACKBAR_CONFIG.styles.info};
    & > *,
    & > div > svg {
      color: white;
      fill: white;
    }
  }
`;
