import { AuthApi } from '../../api/Auth.api';
import { SignInCredentials, UseSignIn } from '../model/SignIn.model';
import { useFormControl } from '../../../../Shared/components/FormControl/hooks/useFormControl';
import { INITIAL_CREDENTIALS, signInValidator,redirectToRealmValidator } from '../validators/SignIn.validators';
import { useContext, useMemo, useState } from 'react';
import { KeycloakContext } from '../../providers/KeycloakProvider';

export const useSignIn = (): UseSignIn => {
  const authApi = AuthApi();
  const { setTenant, loginWithTenant, setCurrentUserEmail } = useContext(KeycloakContext)!;
  const [tenantOptions, setTenantOptions] = useState<Array<{id:string,name:string}>>();

  const signIn = async (credentials: SignInCredentials) => {
    try {
        const res = await authApi.tenant(credentials.email);

        if (!res || !res.content) return;

        const tenants = res.content.filter((tenant): tenant is { id: string; name: string } => tenant !== undefined);

        // Handle multiple organizations
        if (tenants.length > 1) {
            setTenantOptions(tenants);
        } 
        
        // Handle single organization
        else if (setCurrentUserEmail && loginWithTenant && setTenant && tenants.length === 1) {
            const tenant = tenants[0];
            setTenant(tenant.id);
            loginWithTenant(tenant.id);
            setCurrentUserEmail(credentials.email);
        }
    } catch (error) {
      console.log(error);
    }
};


  const redirectToRealm = (credentials: SignInCredentials) =>{
    //Store in context
    if (setCurrentUserEmail && loginWithTenant && setTenant && credentials.email && credentials.organization) {
      //Store in local storage
      //setTenantInStorage(res.content?);
      setTenant(credentials.organization);
      //setRedirectToLogin(true);
      loginWithTenant(credentials.organization);
      setCurrentUserEmail(credentials.email);
    }
  }

  const resetForm = () =>{
    form.resetForm();
    setTenantOptions(undefined)
  }

  const useFormConfig = (tenantOptions?:Array<{id:string,name:string}>) => {
    return useMemo(() => {
      if (tenantOptions) {
        return {
          validator: redirectToRealmValidator,
          handler: redirectToRealm,
        };
      } else {
        return {
          validator: signInValidator,
          handler: signIn,
        };
      }
    }, [tenantOptions]);
  };


  const { validator, handler } = useFormConfig(tenantOptions);
  const form = useFormControl(INITIAL_CREDENTIALS,validator,handler);

  return {
    form,
    loading: authApi.http.loading,
    tenantOptions,
    resetForm
  };
};
